<template>
  <article
    id="content"
    class="page flex flex-col justify-start content-stretch items-stretch h-full"
  >
    <header v-if="$slots.header" id="content-header">
      <slot name="header" />
    </header>
    <slot />
    <footer v-if="$slots.footer" id="content-footer" class="footer">
      <slot name="footer" />
    </footer>
  </article>
</template>
